import React from "react";

const GridLines = ({ totalColumns, totalRows, cellWidth, cellHeight }) => {
    return (
        <>
            {Array.from({ length: totalColumns + 1 }).map((_, colIndex) => (
                <div key={`v-line-${colIndex}`}>
                    <div
                        style={{
                            position: "absolute",
                            left: `${colIndex * cellWidth}px`,
                            top: 0,
                            height: "100%",
                            borderLeft: "0.5px solid rgba(255, 255, 0, 0.5)",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            left: `${colIndex * cellWidth}px`,
                            top: 0,
                            transform: "translate(-50%, 0)",
                            color: "yellow",
                            fontSize: "10px",
                            background: "rgba(0, 0, 0, 0.3)",
                            padding: "2px",
                            borderRadius: "2px",
                        }}
                    >
                        {colIndex}
                    </div>
                </div>
            ))}
            {Array.from({ length: totalRows + 1 }).map((_, rowIndex) => (
                <div key={`h-line-${rowIndex}`}>
                    <div
                        style={{
                            position: "absolute",
                            top: `${rowIndex * cellHeight}px`,
                            left: 0,
                            width: "100%",
                            borderTop: "0.5px solid rgba(255, 255, 0, 0.5)",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: `${rowIndex * cellHeight}px`,
                            left: 0,
                            transform: "translate(0, -50%)",
                            color: "yellow",
                            fontSize: "10px",
                            background: "rgba(0, 0, 0, 0.3)",
                            padding: "2px",
                            borderRadius: "2px",
                        }}
                    >
                        {rowIndex}
                    </div>
                </div>
            ))}
        </>
    );
};

export default GridLines;
