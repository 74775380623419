import { useSelector } from "react-redux";
import { getHotel } from "../actions/hotel";

const useLandingSession = () => {
    const project = useSelector(getHotel);
    const landing = useSelector((state) =>
        project && state.session.selectedLandings ? state.session.selectedLandings[project] : null
    );

    const locationVOD = landing && landing.vodDomain ? "https://" + landing.vodDomain + "/das" : null;
    const theme = landing ? landing.theme : null;

    return { project, landing, theme, salesStyles: landing?.salesStyles, mapsStyles: landing?.mapsStyles, locationVOD };
};

export default useLandingSession;
