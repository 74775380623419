import i18n from "i18n";
import { getDasUrl } from "actions/config";

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export function getPixelPosition(x, y, cellWidth, cellHeight) {
    return {
        left: x * cellWidth,
        top: y * cellHeight,
    };
}

export const predefinedPoints = {
    Restaurantes: [
        { id: 1, name: "Pizza Hut", icon: "🍕", quantity: 3 },
        { id: 2, name: "Domino's", icon: "🍕", quantity: 4 },
        { id: 3, name: "Little Caesars", icon: "🍕", quantity: 3 },
        { id: 4, name: "McDonald's", icon: "🍔", quantity: 5 },
        { id: 5, name: "Burger King", icon: "🍔", quantity: 3 },
    ],
    Hoteles: [
        { id: 6, name: "Hotel Plaza", icon: "🏨", quantity: 2 },
        { id: 7, name: "Hilton Resort", icon: "🏩", quantity: 3 },
    ],
    Parques: [
        { id: 8, name: "Central Park", icon: "🌳", quantity: 3 },
        { id: 9, name: "Green Valley", icon: "🌴", quantity: 2 },
    ],
    Tiendas: [
        { id: 10, name: "Supermercado Express", icon: "🛒", quantity: 3 },
        { id: 11, name: "Mega Mall", icon: "🏬", quantity: 2 },
    ],
    Estaciones: [
        { id: 12, name: "Estación Central", icon: "🚉", quantity: 3 },
        { id: 13, name: "Terminal Express", icon: "🚏", quantity: 4 },
    ],
};

export const generateRandomCategories = () => {
    const totalColumns = 50,
        totalRows = 29;
    const xMax = totalColumns - 1;
    const yMax = totalRows - 1;

    return Object.entries(predefinedPoints).map(([categoryName, items]) => ({
        name: categoryName,
        points: items.flatMap(({ id, name, icon, quantity }) =>
            Array.from({ length: quantity }, () => ({
                id,
                name,
                icon,
                coord: [randomInt(1, xMax), randomInt(1, yMax)],
            }))
        ),
    }));
};

export const arrangeGridPointOfInterest = (pointsOfInterest = []) => {
    const lang = (i18n.language || "en").split("-")[0];
    const dasURL = getDasUrl();

    return pointsOfInterest.flatMap((point) => {
        const { pinIcon = {}, pins = [], id } = point;

        const icon = pinIcon.icon && pinIcon.lib ? { icon: pinIcon.icon, lib: pinIcon.lib } : null;

        const srcImage = pinIcon.libraryRef
            ? `${dasURL}/${pinIcon.libraryRef}?lang=${lang}`
            : pinIcon.externalUrl || null;

        return pins.map((pin) => ({
            x: pin.x,
            y: pin.y,
            icon,
            srcImage,
            id,
            zIndex: 10 + pin.y,
        }));
    });
};

export const getImageUrlAndAdjust = (mapData, dasURL) => {
    if (!mapData) return { imageUrl: null, adjust: null };

    let imageUrl = null;

    if (mapData?.image?.libraryRef) {
        imageUrl = `${dasURL}/${mapData.image.libraryRef}`;
    } else if (mapData?.image?.externalUrl) {
        imageUrl = mapData.image.externalUrl;
    }

    let adjust = null;

    switch (mapData?.image?.adjust) {
        case "cover-adjust":
            adjust = "100% 100%";
            break;
        case "cover":
        case "contain":
            adjust = mapData?.image?.adjust;
            break;
        default:
            adjust = null;
    }

    return { imageUrl, adjust };
};
