import gql from "graphql-tag";

export const GET_INTEREST_POINTS = {
    id: "GET_INTEREST_POINTS",
    query: gql`
        query getInterestPoints($mapID: Int64!, $id: Int64, $page: Int!, $size: Int!) {
            getMapInterestPoints(mapID: $mapID, page: $page, size: $size, filter: { id: $id }) {
                info {
                    count
                    page
                    size
                }
                results {
                    description
                    id
                    image
                    name
                    navigationAction
                    pinIcon
                    pins
                    pos
                }
            }
        }
    `,
    defaultVars: {
        page: 1,
        size: 100,
    },
};

export const GET_MAP = {
    query: gql`
        query GetMap($id: Int64!) {
            getMap(id: $id) {
                id
                name
                nameTID
                image
            }
        }
    `,
    defaultVars: {
        id: 1,
    },
};
export const GET_CATEGORIES = {
    id: "GET_CATEGORIES",
    query: gql`
        query getCategories($mapID: Int64!, $id: Int64, $page: Int!, $size: Int!) {
            getMapCategories(mapID: $mapID, page: $page, size: $size, filter: { id: $id }) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    mapCategoryInterestPoints {
                        mapCategoryID
                        mapInterestPointID
                    }
                    name
                    pos
                }
            }
        }
    `,
    defaultVars: {
        page: 1,
        size: 100,
    },
};
