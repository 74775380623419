import handleGraphqlError from "./handleGraphqlError";
import { useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { GET_CATEGORIES, GET_INTEREST_POINTS, GET_MAP } from "./queries/maps";
import { useState } from "react";
import { getToken as getUserToken } from "actions/user";
import { getToken } from "actions/config";

export const useInterestPoints = () => {
    const [interestPoints, setInterestPoints] = useState([]);
    const [loading, setLoading] = useState(true);

    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();

    const [loadInterestPoints, { error }] = useLazyQuery(GET_INTEREST_POINTS.query, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setInterestPoints([...(data?.getMapInterestPoints?.results || [])].sort((a, b) => a.pos - b.pos));
            setLoading(false);
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            setLoading(false);
        },
    });

    const getInterestPointsFn = (params) => {
        setLoading(true);
        return loadInterestPoints(params || { variables: GET_INTEREST_POINTS.defaultVars });
    };

    return {
        getInterestPoints: getInterestPointsFn,
        interestPoints,
        loading,
        error,
    };
};

export const useMap = () => {
    const [map, setMap] = useState(null);
    const [loading, setLoading] = useState(true);

    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();

    const [loadMap, { error }] = useLazyQuery(GET_MAP.query, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setMap(data?.getMap || null);
            setLoading(false);
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            setLoading(false);
        },
    });

    const getMapFn = (params) => {
        setLoading(true);
        return loadMap(params || { variables: GET_MAP.defaultVars });
    };

    return {
        getMap: getMapFn,
        map,
        loading,
        error,
    };
};

export const useCategories = () => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const [loadCategories, { error }] = useLazyQuery(GET_CATEGORIES.query, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setCategories(
                [...(data?.getMapCategories?.results || [])]
                    .filter((cat) => cat.mapCategoryInterestPoints.length)
                    .sort((a, b) => a.pos - b.pos)
            );
            setLoading(false);
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            setLoading(false);
        },
    });

    const getCategoriesFn = (params) => {
        setLoading(true);
        return loadCategories(params || { variables: GET_CATEGORIES.defaultVars });
    };

    return {
        getCategories: getCategoriesFn,
        categories,
        loading,
        error,
    };
};
