import Sales from "../views/Sales";

export const shopRoutes = (prefix = "") => {
    return [
        {
            path: `${prefix}`,
            exact: true,

            section: Sales,
        },
        {
            path: `${prefix}/:id`,
            exact: true,
            section: Sales,
        },
        {
            path: `${prefix}/:id/:sub`,
            exact: true,

            section: Sales,
        },
    ];
};
