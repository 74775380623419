import React, { useEffect } from "react";
import Header from "components/Header";
import { useInterestPoints } from "graphql/useMaps";
import { useParams } from "react-router-dom";
import { getDasUrl } from "actions/config";
import { useTranslation } from "react-i18next";
import { processActions } from "components/Design/DesignUtils";
import useLandingSession from "hooks/useLandingSession";
import { setSelectedLanding } from "actions/hotel";
import { useDispatch } from "react-redux";
import history from "routes/history";

const InterestPointDetails = () => {
    const { getInterestPoints, interestPoints } = useInterestPoints();
    const { id, poiid } = useParams();
    const dasURL = getDasUrl();
    const { t } = useTranslation();
    const { landing } = useLandingSession();
    useEffect(() => {
        getInterestPoints({ variables: { mapID: id, id: poiid, page: 1, size: 1 } });
    }, []);

    const interestPoint = interestPoints?.[0];
    const imageLibrary = interestPoint?.image?.libraryRef;
    const imageExternal = interestPoint?.image?.externalUrl;
    const hasImage = imageLibrary || imageExternal;

    const goToLanding = (landingId) => {
        history.push(`/#/site?&mapLandingRef=${landingId}`);
        location.reload();
    };
    const editorColors = landing?.mapsStyles;

    const link = interestPoint?.navigationAction
        ? processActions({
              data: {
                  actions: [{ ...interestPoint?.navigationAction, value: `${interestPoint?.navigationAction.value}` }],
              },
              goToLanding,
              id: poiid,
          })
        : null;

    return (
        <section style={{ backgroundColor: "#FBFBFB", color: "#323232" }} className="basic has-top ">
            <Header
                title={interestPoint?.name}
                custom={{ styles: { ...(editorColors?.style?.header?.colors || {}) } }}
            />
            <section>
                <div style={{ height: "90svh" }} className="relative grid grid-rows-2 grid-cols-1 gap-4">
                    {hasImage && (
                        <img
                            src={imageLibrary ? `${dasURL}/${imageLibrary}` : imageExternal}
                            className="w-full h-full object-cover rounded-lg"
                            style={{ maxHeight: "45vh" }}
                        ></img>
                    )}
                    <div
                        className={`relative ${interestPoint?.description ? "overflow-y-auto" : ""}  w-full ${
                            !hasImage ? "row-span-2" : "row-span-1"
                        } `}
                        style={{ maxHeight: !hasImage ? "none" : "45vh" }}
                    >
                        {interestPoint?.description && <p style={{ minHeight: "70%" }}>{interestPoint?.description}</p>}
                        {interestPoint?.navigationAction && interestPoint?.navigationAction?.type != "disabled" && (
                            <div
                                style={{ backgroundColor: "rgb(251, 251, 251, 0.7)", bottom: "0px" }}
                                className={` ${
                                    interestPoint?.description ? "sticky" : "absolute"
                                } flex justify-center items-center w-full py-6`}
                            >
                                <button
                                    className="btn-accent h-10 w-full rounded-sm font-bold"
                                    onClick={() => {
                                        if (link?.onClick) {
                                            link.onClick();
                                        } else {
                                            history.push(link.href);
                                            location.reload();
                                        }
                                    }}
                                >
                                    {t("show-more")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </section>
    );
};

export default InterestPointDetails;
