import React, { useEffect, useState } from "react";
import Tabs from "components/Tabs";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { GET_UPCOMING_BOOKINGS_WHIT_CODE_AND_EMAIL } from "graphql/queries/stays";
import { getStaysData, setStaysData } from "actions/stays";
import { getUserData } from "actions/user";
import { getHotel } from "actions/hotel";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { UseStayBookings } from "graphql/stays";
import { Redirect } from "react-router-dom";

const AddStay = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const staysData = useSelector(getStaysData);
    const user = useSelector(getUserData);
    const hotel = useSelector(getHotel);
    const [email, setEmail] = useState(user?.email ?? "");
    const [code, setCode] = useState("");
    const [redirectTo, setRedirectTo] = useState("");
    const { data, query } = UseStayBookings(GET_UPCOMING_BOOKINGS_WHIT_CODE_AND_EMAIL);

    function SearchStayBooking() {
        if (staysData && staysData.includes(code)) {
            toast(t("code-already-exist"));
            setRedirectTo("/stays");
        } else {
            query({ email: email, codes: [code] });
        }
    }

    useEffect(() => {
        if (data?.results.length > 0) {
            if (staysData) {
                dispatch(setStaysData({ user: user?.ref ?? "any", values: [...staysData, data?.results[0].code] }));
            } else {
                dispatch(setStaysData({ user: user?.ref ?? "any", values: [data.results[0].code] }));
            }
            if (data?.results[0]?.state == "COMPLETED") {
                setRedirectTo("/stays");
            } else {
                setRedirectTo("/stays/status=upcoming");
            }
        } else if (data?.results.length === 0) {
            toast(t("incorrect-email-or-code"));
        }
    }, [data]);

    useEffect(() => {
        if (!hotel) {
            setRedirectTo("/site");
        }
    }, [hotel]);

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }
    return (
        <section className="basic has-tabs has-top">
            <Header title={t("add-booking")} />
            <section className=" h-screen">
                <div className="relative h-full">
                    <span className={"float-left font-medium text-sm  "}>{t("email")}</span>
                    <input
                        type="text"
                        name="email"
                        id="input-email"
                        value={email}
                        placeholder={t("email-example")}
                        onChange={(e) => {
                            setEmail(e.target.value.toLowerCase());
                        }}
                    />
                    <span className={"float-left font-medium text-sm mt-4"}>{t("booking-code")}</span>
                    <input
                        type="text"
                        name="code"
                        value={code}
                        id="input-code"
                        placeholder={t("code-example")}
                        onChange={(e) => {
                            setCode(e.target.value);
                        }}
                    />
                    <button
                        id="add-booking-button"
                        className=" absolute bottom-0 left-0 rounded w-full font-bold text-xl py-2 bg-accent text-accent-contrast"
                        onClick={SearchStayBooking}
                    >
                        {t("add")}
                    </button>
                </div>
            </section>
            <Tabs />
        </section>
    );
};

export default AddStay;
