import SingleMap from "views/SingleMap";
import Maps from "views/Maps";
import InterestPointDetails from "views/InterestPointDetails";

export const mapsRoutes = (prefix = "") => {
    return [
        {
            path: `${prefix}`,
            exact: true,
            section: Maps,
        },
        {
            path: `${prefix}/:id`,
            exact: true,
            section: SingleMap,
        },
        {
            path: `${prefix}/:id/:poiid`,
            exact: true,
            section: InterestPointDetails,
        },
    ];
};
