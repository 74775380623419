import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import i18n from "i18n";

import GridLines from "./GridLines";
import GridPin from "./GridPin";
import { useGridCalculations, useContainerDimensions } from "./hooks";
import { getDasUrl } from "actions/config";
import { getImageUrlAndAdjust } from "./utils";

library.add(fas, fab);

const MapGrid = forwardRef(
    (
        {
            categories,
            activeCategory,
            setActiveCategory,
            showGridLines = false,
            interestPoints,
            activePoint,
            mapData,
            setActivePoint,
        },
        ref
    ) => {
        const rectRef = useRef(null);
        const dimensions = useContainerDimensions(rectRef);
        const [zoomScale, setZoomScale] = useState(1);

        const totalColumns = 50;
        const totalRows = 29;
        const dasURL = getDasUrl();
        const lang = (i18n.language || "en").split("-")[0];

        const { cellWidth, cellHeight, filteredPoints, largestClusterBox, centerAreaBox } = useGridCalculations({
            categories,
            activeCategory,
            dimensions,
            totalColumns,
            totalRows,
        });

        const imageBackgroundAndAdjust = useMemo(() => {
            return getImageUrlAndAdjust(mapData, dasURL);
        }, [mapData, dasURL]);

        const { imageUrl, adjust } = imageBackgroundAndAdjust;

        const finalInterestPoints = useCallback(() => {
            if (activeCategory === null || activeCategory === "all") {
                return interestPoints;
            }
            return filteredPoints;
        }, [filteredPoints, activeCategory, interestPoints]);

        const zoomToElementRef = useRef(null);
        const centerViewRef = useRef(null);
        const wrapperRef = useRef(null);
        const setTransformRef = useRef(null);

        useImperativeHandle(ref, () => ({
            zoomToElement: (target) => {
                if (zoomToElementRef.current) {
                    zoomToElementRef.current(target);
                }
            },
            centerView: () => {
                if (zoomToElementRef.current) {
                    zoomToElementRef.current("center-area");
                }
            },
        }));

        return (
            <div
                ref={rectRef}
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                    touchAction: "none",
                }}
            >
                <TransformWrapper
                    minScale={1}
                    limitToBounds={true}
                    wheel={{ disabled: false }}
                    pinch={{ disabled: false }}
                    doubleClick={{ disabled: true }}
                    initialScale={1}
                    disablePadding={true}
                    onTransformed={(state) => setZoomScale(state.state.scale)}
                    ref={wrapperRef}
                >
                    {({ zoomToElement, setTransform, centerView }) => {
                        zoomToElementRef.current = zoomToElement;
                        centerViewRef.current = centerView;
                        setTransformRef.current = setTransform;
                        return (
                            <TransformComponent
                                wrapperStyle={{ width: "100%", height: "100%" }}
                                contentStyle={{ width: "100%", height: "100%" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: dimensions.width,
                                            height: dimensions.height,
                                            backgroundImage: `url(${imageUrl})`,
                                            backgroundSize: adjust,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            position: "relative",
                                        }}
                                    >
                                        {showGridLines && (
                                            <GridLines
                                                totalColumns={totalColumns}
                                                totalRows={totalRows}
                                                cellWidth={cellWidth}
                                                cellHeight={cellHeight}
                                            />
                                        )}

                                        <div
                                            id="center-area"
                                            style={{
                                                position: "absolute",
                                                left: centerAreaBox.left,
                                                top: centerAreaBox.top,
                                                width: centerAreaBox.width,
                                                height: centerAreaBox.height,
                                                zIndex: 11,
                                            }}
                                        />

                                        {largestClusterBox && (
                                            <div
                                                id="largest-cluster"
                                                style={{
                                                    position: "absolute",
                                                    left: largestClusterBox.left,
                                                    top: largestClusterBox.top,
                                                    width: largestClusterBox.width,
                                                    height: largestClusterBox.height,
                                                    // backgroundColor: "rgba(135, 206, 235, 0.5)",
                                                    zIndex: 11,
                                                }}
                                            />
                                        )}

                                        {finalInterestPoints().map((point, index) => (
                                            <GridPin
                                                key={`${point.id}-${index}`}
                                                x={point.x}
                                                y={point.y}
                                                id={point.id}
                                                pinIcon={point.pinIcon}
                                                cellWidth={cellWidth}
                                                cellHeight={cellHeight}
                                                zoomScale={zoomScale}
                                                zIndex={point.zIndex}
                                                icon={point.icon}
                                                imageSrc={point.srcImage}
                                                activePoint={activePoint}
                                                setActivePoint={setActivePoint}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </TransformComponent>
                        );
                    }}
                </TransformWrapper>
            </div>
        );
    }
);

MapGrid.displayName = "MapGrid";
export default MapGrid;
