import React from "react";
import Header from "components/Header";
import { useTranslation } from "react-i18next";

const Maps = () => {
    const { t } = useTranslation();

    return (
        <section className="basic has-top">
            <Header title={t("maps")} />
            {/* <div className=" mt-14 bg-red-100 h-14 w-full "></div>
            < */}
            <section>
                <div className=" w-full flex justify-center items-center">
                    <p>{t("sooon........")}</p>
                </div>
            </section>
        </section>
    );
};

export default Maps;
