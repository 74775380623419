import React, { useEffect, useState } from "react";

const SliderContainer = (props) => {
    const { className, items, onSlide, currentPosition, style } = props;

    const [position, setPosition] = useState(0);
    const [difference, setDifference] = useState(0);
    const [startX, setStartX] = useState(0);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            let finalPosition = 0;
            if (difference > 10) {
                setPosition((prevIndex) => {
                    finalPosition = prevIndex === items.length - 1 ? prevIndex : prevIndex + 1;
                    return finalPosition;
                });
            } else if (difference < -10) {
                setPosition((prevIndex) => {
                    finalPosition = prevIndex === 0 ? prevIndex : prevIndex - 1;
                    return finalPosition;
                });
            }
            if (onSlide && typeof onSlide == "function" && difference != 0) {
                onSlide({ value: items?.[finalPosition]?.value, position: finalPosition });
            }
        }, 100);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [difference]);

    useEffect(() => {
        if (currentPosition != position && typeof currentPosition == "number") {
            setPosition(currentPosition);
        }
    }, [currentPosition]);

    useEffect(() => {
        const container = document.getElementById("carrousel-items-container");
        const itemsIterationWidth = (container.offsetWidth - 32) * 0.9 * position;
        const dinamicMargin = (container.offsetWidth - 32) * 0.05;
        const margin = 8 * position + 16;
        const xPosition =
            position != items?.length - 1 ? itemsIterationWidth + margin - dinamicMargin : container.scrollWidth;
        container.scroll(position != 0 ? xPosition : 0, 0);
    }, [position]);

    const handleTouchStart = (e) => {
        if (e.touches) {
            setStartX(e.touches[0].clientX);
        } else {
            setStartX(e.clientX);
        }
    };

    const handleTouchMove = (e) => {
        const currentX = e.touches ? e.touches[0].clientX : e.clientX;
        const difference = startX - currentX;
        setDifference(difference);
    };

    return (
        <div
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onMouseUp={handleTouchMove}
            onMouseDown={handleTouchStart}
            id="carrousel-items-container"
            style={{ scrollBehavior: "smooth", ...style }}
            className={`${className} overflow-hidden flex w-full flex-row gap-2 px-4`}
        >
            {items?.map((i, index) => (
                <div
                    key={`carrousel-item-${index}`}
                    style={{
                        flex: `0 0 90%`,
                    }}
                >
                    {i.item}
                </div>
            ))}
        </div>
    );
};

export default SliderContainer;
