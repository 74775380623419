import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { getHotel } from "actions/hotel";
import { GET_PROJECT_LANGUAGES } from "./queries/user";
import { useEffect, useMemo, useState } from "react";
import i18n from "i18n";

export const useGetContentLanguages = () => {
    const selectedHotelRef = useSelector(getHotel);
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const storageLangContent = localStorage.getItem("storage_lang_content") || i18nLang;
    const [stateLoading, setStateLoading] = useState(true);

    const [fetchLanguages, { data, error, called }] = useLazyQuery(GET_PROJECT_LANGUAGES.query, {
        variables: {
            hotelRef: selectedHotelRef,
        },
        onCompleted: () => {
            const timer = setTimeout(() => {
                setStateLoading(false);
            }, 1000);

            return () => clearTimeout(timer);
        },
        onError: () => {
            setStateLoading(false);
        },
    });

    useEffect(() => {
        if (selectedHotelRef) {
            fetchLanguages();
        } else {
            setStateLoading(false);
        }
    }, [selectedHotelRef]);

    const languages = useMemo(() => {
        if (data && data.getProjectLanguages && data.getProjectLanguages.results) {
            return data.getProjectLanguages.results.map((item) => item.languageRef);
        }
        return ["es", "en"];
    }, [data]);

    const defaultLanguage = useMemo(() => {
        if (data && data.getProjectLanguages && data.getProjectLanguages.results) {
            const defaultLang = data.getProjectLanguages.results.find((lang) => lang.isDefault);
            return defaultLang ? defaultLang.languageRef : undefined;
        }
        return storageLangContent;
    }, [data, storageLangContent]);

    const contentLanguageStillAvailable = useMemo(() => {
        if (data && !error && called) {
            return languages.includes(storageLangContent);
        }
        return null;
    }, [data, error, called]);

    return {
        fetchLanguages,
        languages,
        defaultLanguage,
        contentLanguageStillAvailable,
        loading: stateLoading,
        error,
    };
};
