import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import Header from "components/Header";
import SliderContainer from "components/SliderContainer";
import TabSlider from "components/TabSlider";
import MapGrid from "components/MapGrid/MapGrid";
import Loading from "components/Loading";
import PointOfInterest from "components/MapGrid/PointOfInterest";
import { useInterestPoints, useCategories, useMap } from "graphql/useMaps";
import { useMapDataGrid } from "components/MapGrid/hooks";
import useLandingSession from "hooks/useLandingSession";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { setSelectedLanding } from "actions/hotel";

const SingleMap = () => {
    const { id } = useParams();
    const headerRef = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const path = useRef("");
    const loc = useLocation();
    const [activeCategory, setActiveCategory] = useState("all");
    const [filteredInterestPoints, setFilteredInterestPoints] = useState([]);
    const [positionInterestPointSlider, setPositionInterestPointSlider] = useState(0);
    const { getInterestPoints, loading: loadingInterestPoints, interestPoints } = useInterestPoints();
    const { getCategories, loading: loadingCategories, categories } = useCategories();
    const { getMap, loading: loadingMap, map } = useMap();
    const { gridPoints, gridCategories, activePoint, setActivePoint } = useMapDataGrid(interestPoints, categories);
    const mapGridRef = useRef(null);
    const { landing } = useLandingSession();

    // console.log(gridCategories);
    const designColors = landing?.theme?.colors;
    const editorColors = landing?.mapsStyles;

    useEffect(() => {
        getInterestPoints({ variables: { mapID: id, page: 1, size: 100 } });
        getCategories({ variables: { mapID: id, page: 1, size: 100 } });
        getMap({ variables: { id } });
    }, []);

    useEffect(() => {
        if (path.current == "") {
            path.current = loc.pathname;
        } else if (loc.pathname != path.current) {
            location.reload();
            path.current = loc.pathname;
        }
    }, [loc]);

    useEffect(() => {
        if (interestPoints.length) {
            if (activeCategory == "all") {
                setFilteredInterestPoints(interestPoints);
            } else {
                const category = categories.find((cat) => cat.id == activeCategory);
                setFilteredInterestPoints(
                    category?.mapCategoryInterestPoints?.map((value) =>
                        interestPoints.find((val) => val.id == value.mapInterestPointID)
                    )
                );
            }
            setPositionInterestPointSlider(0);
        }
    }, [interestPoints, activeCategory]);

    const categoryOptions = useMemo(() => {
        const defaultOption = {
            value: "all",
            label: t("all"),
            onClick: () => {
                setActiveCategory("all");
                setTimeout(() => {
                    mapGridRef.current?.zoomToElement("largest-cluster");
                }, 200);
            },
        };
        const otherOptions = categories.map((cat) => ({
            value: cat.id,
            label: cat.name,
            onClick: () => {
                setActiveCategory(cat.id);
                setTimeout(() => {
                    mapGridRef.current?.zoomToElement("largest-cluster");
                }, 200);
            },
        }));
        return [defaultOption, ...otherOptions];
    }, [categories]);

    const mapData = useMemo(() => {
        if (map) {
            return {
                id: map.id,
                name: map.name,
                image: map.image,
            };
        }
        return null;
    }, [map]);

    useEffect(() => {
        setTimeout(() => {
            mapGridRef.current?.centerView();
        }, 100);
    }, []);

    const loading = loadingInterestPoints || loadingCategories || loadingMap;

    useEffect(() => {
        let pos = 0;

        setPositionInterestPointSlider((current) => {
            filteredInterestPoints.find((el, index) => {
                if (el.id == activePoint) {
                    pos = index;
                }
                return el.id == activePoint;
            });
            return pos != current ? pos : current;
        });
    }, [activePoint]);

    const goToLanding = (landingId) => {
        location.href = `/#/site?&mapLandingRef=${landingId}`;
    };

    return (
        <section className="basic has-top">
            {loading && <Loading />}
            <Header
                ref={headerRef}
                title={mapData?.name}
                mainMap={landing?.mainMap && id !== landing?.mainMap}
                backAction={
                    landing?.goBackToSite
                        ? () => {
                              location.href = "/#/site";
                          }
                        : null
                }
                mainMapAction={() => {
                    location.href = `/#/maps/${landing?.mainMap}`;
                    dispatch(setSelectedLanding({ ...landing, mainMap: null, goBackToSite: true }));
                }}
                custom={{ styles: { ...(editorColors?.style?.header?.colors || {}) } }}
            />
            <div
                style={{
                    opacity: loading ? 0 : 1,
                    transition: "opacity 0.3s",
                }}
                className=" max-w-lg mx-auto relative"
            >
                <TabSlider
                    className="h-14 mt-14"
                    selected={!activeCategory ? "all" : activeCategory}
                    selectedStyle={{
                        color:
                            editorColors?.style?.tabs?.status?.active?.colors?.fgColor ||
                            designColors?.activeForeground,
                        backgroundColor:
                            editorColors?.style?.tabs?.status?.active?.colors?.bgColor ||
                            designColors?.activeBackground,
                    }}
                    notSelectedStyle={{
                        color:
                            editorColors?.style?.tabs?.status?.default?.colors?.fgColor ||
                            designColors?.defaultForeground,
                        backgroundColor:
                            editorColors?.style?.tabs?.status?.default?.colors?.bgColor ||
                            designColors?.defaultBackground,
                    }}
                    options={categoryOptions}
                />
                <div
                    style={{ height: `calc(100svh - 7rem)`, width: `100%` }}
                    className="relative bg-white flex items-start justify-center"
                >
                    <MapGrid
                        mapData={mapData}
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        activePoint={activePoint}
                        setActivePoint={setActivePoint}
                        ref={mapGridRef}
                        categories={gridCategories}
                        interestPoints={gridPoints}
                    />

                    <SliderContainer
                        className="absolute"
                        style={{ bottom: "30px" }}
                        onSlide={({ value: pointId, position }) => {
                            setActivePoint(pointId);
                            setPositionInterestPointSlider(position);
                        }}
                        currentPosition={positionInterestPointSlider}
                        items={filteredInterestPoints.map((point, index) => ({
                            item: (
                                <PointOfInterest {...point} landing={landing} index={index} goToLanding={goToLanding} />
                            ),
                            value: point.id,
                        }))}
                    />
                </div>
            </div>
        </section>
    );
};

export default SingleMap;
