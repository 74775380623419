import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import { getDasUrl } from "actions/config";
import { processActions } from "components/Design/DesignUtils";
import { useDispatch } from "react-redux";
import { setSelectedLanding } from "actions/hotel";

const PointOfInterest = (props) => {
    const { name, id: poiId, image, index, description, navigationAction, goToLanding, landing } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [redirectTo, setRedirectTo] = useState("");
    const { id } = useParams();
    const dasURL = getDasUrl();

    const showButton = image || description || (navigationAction && navigationAction?.type != "disabled");

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const link = navigationAction
        ? processActions({
              data: { actions: [{ ...navigationAction, value: `${navigationAction.value}` }] },
              goToLanding,
              id: poiId,
          })
        : null;

    return (
        <div
            key={"item-" + index}
            style={{ gap: "10px", height: "106px" }}
            className="w-full bg-white rounded-md py-2 px-4 flex h-full border border-gray-200 shadow-md"
        >
            {image && (image.externalUrl || image.libraryRef) && (
                <img
                    className="w-32 h-full rounded object-cover"
                    src={image?.libraryRef ? `${dasURL}/${image?.libraryRef}` : image.externalUrl}
                    alt={name}
                />
            )}
            <div className="flex flex-col justify-between">
                <p className="text-black">{name}</p>
                {showButton && (
                    <button
                        className="text-left text-accent"
                        style={{ fontWeight: 500 }}
                        onClick={() => {
                            if ((image && (image.externalUrl || image.libraryRef)) || description) {
                                setRedirectTo(`/maps/${id}/${poiId}`);
                            } else {
                                if (link?.onClick) {
                                    link.onClick();
                                } else {
                                    location.href = link.href;
                                    location.reload();
                                }
                            }
                            dispatch(setSelectedLanding({ ...landing, mainMap: id, goBackToSite: false }));
                        }}
                    >
                        {t("show-more")}
                    </button>
                )}
            </div>
        </div>
    );
};

export default PointOfInterest;
