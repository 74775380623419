import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useContent } from "../graphql/useHotels";
import { useTranslation } from "react-i18next";
import { getSelectedHotel, setSelectedLanding, getHotels } from "../actions/hotel";
import { getDasUrl, getIsEmbedded } from "../actions/config";
import { toast } from "react-toastify";
import { getPortalSession } from "../actions/portal";
import { setCuinerData } from "../actions/cuiner";

import useWindowDimensions, {
    arrangeStyles,
    postMessageToParent,
    arrangeThemeStyles,
    deviceTypes,
    getDeviceInfo,
    getFontURL,
} from "../components/Design/DesignUtils";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import Tabs from "../components/Tabs";
import UseWidgetContainer from "../components/Design/UseWidgetContainer";
import Header from "../components/Header";
import useLandingSession from "../hooks/useLandingSession";
import _ from "lodash"; // eslint-disable-line
import { getRoom, updateRoom } from "../modules/rooms/store";
import { useGetContentLanguages } from "graphql/useGetContentLanguages";
import { useSetTokenLanguage } from "graphql/useUser";
import history from "routes/history";

const staticSizes = {
    topBar: 56,
    roomWidget: 80,
    tabs: 64,
};

const Landing = ({ data: screenData, isPortal }) => {
    // Data
    const portalData = screenData ? screenData.portal : null;
    const cuinerConfig = screenData ? screenData.cuiner : null;

    const session = useSelector(isPortal ? getPortalSession : () => null);

    // Tools
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const { height: pageHeight, width: pageWidth } = useWindowDimensions();
    const { landing, theme } = useLandingSession();

    const isEmbedded = useSelector(getIsEmbedded);
    const project = useSelector(getSelectedHotel);
    const projects = useSelector(getHotels);
    const room = useSelector(getRoom);
    const pairingSuccess = room?.pairingEnabled && room?.hasChromecast;
    const pairingMessageEnabled = room?.showPairingMessage;
    const params = new URLSearchParams(window.location.href.substring(window.location.href.lastIndexOf("/") + 1));
    const backRef = useRef();
    const cuinerData = useSelector((state) =>
        project?.ref && state.session.cuinerData ? state.session.cuinerData[project.ref] : null
    );

    // Get content
    const { content: getContent, loading: loadingContent, error: errorContent, data: contentInfo } = useContent();
    const { contentLanguageStillAvailable, defaultLanguage } = useGetContentLanguages();

    const { setTokenLanguage } = useSetTokenLanguage();

    // States
    const [deviceInfo, setDeviceInfo] = useState(
        getDeviceInfo({
            width: !isPortal && pageWidth > 512 ? 512 : pageWidth,
            height: pageHeight,
            isPortal: isPortal,
        })
    );
    const [grid, setGrid] = useState(null);
    const [widgets, setWidgets] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [contentData, setContentData] = useState(null);
    const [staticWidgetRoom, setStaticWidgetRoom] = useState({
        type: "rooms",
        styles: { position: "absolute", width: 0, height: staticSizes.roomWidget, left: 0, top: 0 },
        data: {
            id: `staticWidgetRoom`,
            style: arrangeThemeStyles({ elementStyles: [], theme: landing ? landing.theme : null }),
        },
    });
    const [baseStyles, setBaseStyles] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const [canGoBack, setCanGoBack] = useState(false);

    const portalStep = session ? session.getStep() : null;
    const portalFullMode = portalStep && portalStep != "login" && portalStep !== "signup";

    // Info
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const dasURL = getDasUrl();
    const isMainScreen =
        landing && landing.landingRef && landing.mainLandingRef && landing.landingRef === landing.mainLandingRef;
    const hasTopBar = !isPortal;
    const hasTabs = !isPortal;
    const hasRoomWidget = isMainScreen && !isPortal;
    const topBarHeight = hasTopBar ? staticSizes.topBar : 0;
    const roomWidgetHeight = hasRoomWidget ? staticSizes.roomWidget : 0;

    const Fonts = ensureFonts(theme, widgets);

    // Determine content data
    const evalContent = () => {
        let content = null;
        if (portalData) {
            content = portalData[String(deviceInfo.type).toLowerCase()] || {};
        } else if (contentInfo && contentInfo.getContent) {
            content = contentInfo.getContent;
        }
        setContentData(content);
    };

    // URL history change
    useEffect(() => {
        window.onpopstate = () => {
            if (window.location.href) {
                const regex = /[^[/#]+]*$/gm;
                const location = regex.exec(window.location.href);
                if (location && location.length > 0) {
                    const path = location[0];
                    if (path === "site" && !isEmbedded) {
                        goBack();
                    }
                }
            }
        };
        return () => {
            clearTimeout(backRef.current);
        };
    }, []); // eslint-disable-line

    // Windows resize
    useEffect(() => {
        if (contentData) {
            setBaseStyles(arrangeStyle(contentData && contentData.style ? contentData.style : null));
        }
        setDeviceInfo(
            getDeviceInfo({
                width: !isPortal && pageWidth > 512 ? 512 : pageWidth,
                height: pageHeight,
                isPortal: isPortal,
            })
        );
        // eslint-disable-next-line
    }, [pageWidth, pageHeight]);

    // Device type changed
    useEffect(() => {
        if (contentData) {
            setBaseStyles(arrangeStyle(contentData && contentData.style ? contentData.style : null));
        }
        if (isPortal && deviceInfo) {
            evalContent();
        }
    }, [deviceInfo, contentData]); // eslint-disable-line

    // Landing change
    useEffect(() => {
        if (loading) {
            setWidgets(null);
            setContentData(null);
            setGrid(null);
            setBaseStyles(null);
            if (landing && landing.designRef && landing.landingRef) {
                const dataRequest = {
                    ref: landing.landingRef,
                    designRef: landing.designRef,
                    hotelRef: project.ref,
                };
                if (room) {
                    dataRequest.roomNumber = room?.number;
                    dataRequest.password = room.code;
                }
                getContent({ variables: dataRequest });
            } else {
                setLoading(false);
            }
        }
        if (params.get("mapLandingRef")) {
            setCanGoBack(true);
        } else {
            setCanGoBack(
                (projects && projects.length > 1) || (landing && landing.history && landing.history.length > 0)
            );
        }
        // eslint-disable-next-line
    }, [landing]);

    // Landing loading status change
    useEffect(() => {
        if (!loadingContent) {
            setLoading(false);
            evalContent();
        }
        // eslint-disable-next-line
    }, [loadingContent, contentInfo]);

    // Landing loading error
    useEffect(() => {
        if (errorContent) {
            setError(errorContent);
            if (params.get("mapLandingRef")) {
                backRef.current = setTimeout(() => {
                    history.goBack();
                }, 2000);
            }
        }
    }, [errorContent]);

    // Content data change
    useEffect(() => {
        if (contentData) {
            setBaseStyles(arrangeStyle(contentData && contentData.style ? contentData.style : null));
        }
        // eslint-disable-next-line
    }, [contentData]);

    // Content grid change
    useEffect(() => {
        if (grid) {
            setWidgets(arrangeWidgets(contentData && contentData.widgets ? contentData.widgets : null));
        }
        // eslint-disable-next-line
    }, [grid, contentData]);

    useEffect(() => {
        if (contentLanguageStillAvailable !== null && contentLanguageStillAvailable === false && defaultLanguage) {
            setTokenLanguage({
                variables: {
                    language: defaultLanguage,
                },
            });
            i18n.changeLanguage(defaultLanguage);
            localStorage.setItem("storage_lang_content", defaultLanguage);
            localStorage.setItem("storage_lang_i18", defaultLanguage);
        }
    }, [contentLanguageStillAvailable]);

    const getTopBarConfig = () => {
        let topbar = null;
        if (widgets && widgets.length > 0) {
            widgets.map((widget) => {
                if (widget && widget.type === "topbar") {
                    topbar =
                        widget.data && widget.data.config
                            ? {
                                  data: widget.data.config.topbar,
                                  styles: widget.data.config.styles,
                              }
                            : null;
                }
                return widget;
            });
        }
        return topbar;
    };

    // Styles changed
    useEffect(() => {
        setGrid(
            arrangeGrid({
                device: deviceInfo,
                grid: contentData ? contentData.grid : null,
            })
        );
        // eslint-disable-next-line
    }, [contentData, deviceInfo]);

    const goToLanding = (landingId, designRef, noAddHistory, mainLandingRef) => {
        let selectedLandingClone = { ...landing };
        let history = selectedLandingClone.history ? selectedLandingClone.history : [];
        if (!noAddHistory && selectedLandingClone.landingRef !== landingId)
            history.push(selectedLandingClone.landingRef);
        selectedLandingClone.landingRef = landingId;
        if (designRef) selectedLandingClone.designRef = designRef;
        if (mainLandingRef) selectedLandingClone.mainLandingRef = mainLandingRef;
        selectedLandingClone.topBar = null;
        selectedLandingClone.history = history;
        setLoading(true);
        dispatch(setSelectedLanding(selectedLandingClone));
    };

    const goBack = async () => {
        let selectedLandingClone = { ...landing };
        let goToHotelList = true;
        if (selectedLandingClone && selectedLandingClone.history && selectedLandingClone.history.length > 0) {
            goToHotelList = false;
            selectedLandingClone.landingRef = selectedLandingClone.history[selectedLandingClone.history.length - 1];
            selectedLandingClone.topBar = null;
            selectedLandingClone.history.pop();
            dispatch(setSelectedLanding(selectedLandingClone));
            setLoading(true);
        }
        if (params.get("mapLandingRef")) {
            goToHotelList = false;
            history.goBack();
        }
        if (goToHotelList) {
            if (isEmbedded) {
                postMessageToParent({ action: "exit" });
                return;
            } else {
                setRedirectTo("/site/list");
            }
        }
    };

    const goToRoomShop = async (roomShopRef) => {
        setRedirectTo(`/site/room/${roomShopRef}`);
    };

    const goToMessages = async () => {
        if (project?.ref && room) {
            setRedirectTo("/messages");
        } else {
            toast(t("have a room?"));
        }
    };

    const arrangeStyle = (style) => {
        let styles = arrangeStyles({
            style: arrangeThemeStyles({
                elementStyles: style,
                theme: landing ? landing.theme : null,
                widgetStyles: false,
            }),
            dasURL: dasURL,
            lang: lang,
            useParentBackground: true,
            useBackground: !isPortal,
        });
        styles.position = "relative";
        styles.paddingTop = styles.marginTop && styles.marginTop > 0 ? styles.marginTop : 0;
        styles.paddingBottom = styles.marginBottom && styles.marginBottom > 0 ? styles.marginBottom : 0;
        styles.paddingLeft = styles.marginLeft && styles.marginLeft > 0 ? styles.marginLeft : 0;
        styles.paddingRight = styles.marginRight && styles.marginRight > 0 ? styles.marginRight : 0;
        styles.marginTop = 0;
        styles.marginBottom = 0;
        styles.marginLeft = 0;
        styles.marginRight = 0;
        styles.width = deviceInfo.width || 0;
        styles.itemsMargin = style ? style.itemsMargin : 0;
        if (!isPortal && !styles.backgroundColor && !styles.parentBackground) {
            styles.backgroundColor = "#fff";
        }
        return styles;
    };

    const arrangeWidgets = (widgetsConfig) => {
        let widgets = [];

        let widgetData = null;
        let staticWidgetRoomClone = { ...staticWidgetRoom };
        if (widgetsConfig && grid) {
            const styles = baseStyles || {};
            staticWidgetRoomClone.styles.width = grid ? grid.cols.size * grid.cols.max : styles ? styles.width : 0;
            widgetsConfig.map((widgetConfig, index) => {
                let widgetDataStyle = arrangeThemeStyles({
                    elementStyles: widgetConfig.style,
                    theme: landing ? landing.theme : null,
                    widgetStyles: false,
                });
                widgetData = {
                    type: widgetConfig.type,
                    styles: {
                        position: "absolute",
                        width: 0,
                        height: 0,
                        left: styles.paddingLeft || 0,
                        top: styles.paddingTop || 0,
                        backgroundColor: "transparent",
                        overflow: `${
                            widgetConfig.type === "wifilogin" ||
                            widgetConfig.type === "text" ||
                            widgetConfig.type === "messages"
                                ? "visible"
                                : "hidden"
                        }`,
                        fontFamily: "",
                    },
                    data: {
                        style: widgetDataStyle,
                        config: { ...widgetConfig.config, styles: widgetConfig.style },
                        id: `${widgetConfig.type}-${index}`,
                        actions: widgetConfig.actions,
                        fonts: widgetConfig.fonts,
                        deviceType: deviceInfo.type,
                    },
                    nesteds: widgetConfig.widgets,
                };
                if (widgetConfig.size) {
                    if (widgetConfig.size.W)
                        widgetData.styles.width =
                            widgetConfig.size.W * grid.cols.size - (styles.paddingLeft + styles.paddingRight) || 0;
                    if (widgetConfig.size.H)
                        widgetData.styles.height =
                            widgetConfig.size.H * grid.rows.size -
                                (styles && styles.itemsMargin && styles.itemsMargin.H ? styles.itemsMargin.H : 0) || 0;
                }
                if (widgetConfig.position) {
                    if (widgetConfig.position.X) widgetData.styles.left += widgetConfig.position.X * grid.cols.size;
                    if (widgetConfig.position.Y) widgetData.styles.top += widgetConfig.position.Y * grid.rows.size;
                }
                if (widgetConfig.fonts && widgetConfig.fonts instanceof Array && widgetConfig.fonts.length > 0) {
                    if (widgetConfig.type === "button" || widgetConfig.type === "messages") {
                        const font = widgetConfig.fonts.find((font) => font);
                        widgetData.styles.fontFamily = font.name;
                    }
                }
                if (widgetConfig.type === "rooms") {
                    if (
                        widgetData &&
                        widgetData.data &&
                        widgetData.data.style &&
                        widgetData.data.style.bgColor === null
                    ) {
                        widgetData.data.style.bgColor = "#ffffff";
                    }
                    staticWidgetRoomClone.data = widgetData.data;
                    staticWidgetRoomClone.nesteds = widgetData.nesteds;
                } else {
                    widgets.push(widgetData);
                }
                return widgetConfig;
            });
        }
        setStaticWidgetRoom(staticWidgetRoomClone);
        return widgets;
    };

    const goToCuiner = () => {
        if (project?.ref && room) {
            if (cuinerConfig && cuinerConfig.appId && cuinerConfig.token && cuinerConfig.url && cuinerConfig.user) {
                dispatch(
                    setCuinerData({
                        ...cuinerData,
                        api: {
                            url: cuinerConfig.url,
                            token: cuinerConfig.token,
                            user: cuinerConfig.user,
                            applicationId: cuinerConfig.appId,
                        },
                        selectedRoom: room?.number,
                    })
                );
                setRedirectTo("/pos");
            } else {
                toast("Shop configuration error");
            }
        } else {
            toast(t("have a room?"));
        }
    };

    const screenBackground =
        !isPortal && baseStyles && baseStyles.parentBackground
            ? {
                  ...baseStyles.parentBackground,
                  position: "fixed",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  zIndex: 0,
              }
            : null;

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const showTopbar = !isPortal && widgets !== null;
    const showTabs = !isPortal;

    const maxY = (() => {
        let height = 0;
        if (widgets) {
            widgets.forEach((w) => {
                height = Math.max(height, w?.styles?.top + w?.styles?.height);
            });
        }
        return height;
    })();

    return (
        <>
            {Fonts}
            <section
                className={
                    (isPortal ? "portal " : "") +
                    "basic w-full" +
                    (showTopbar ? " has-top" : "") +
                    (showTabs ? " has-tabs" : "")
                }
                style={isPortal ? { background: "none" } : null}
            >
                {showTopbar ? (
                    <Header
                        title={project ? project.name : null}
                        back={canGoBack ? undefined : canGoBack}
                        backAction={() => goBack()}
                        custom={getTopBarConfig()}
                    ></Header>
                ) : null}

                <section
                    id="first-landing-container"
                    style={{
                        position: "relative",
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: topBarHeight,
                        paddingBottom: 0,
                        ...(deviceInfo.type === deviceTypes.DESKTOP ? { maxWidth: "none", width: "100%" } : null),
                    }}
                >
                    {!contentData && error ? (
                        <ErrorMessage type="server" error={error} />
                    ) : loading ? (
                        <Loading />
                    ) : portalFullMode || (widgets && widgets.length > 0) ? (
                        <div>
                            <div style={screenBackground}></div>
                            {hasRoomWidget && (
                                <div className="flex w-full relative" style={{ height: staticSizes.roomWidget }}>
                                    {staticWidgetRoom ? (
                                        <UseWidgetContainer
                                            id="addroom"
                                            type={staticWidgetRoom.type}
                                            currentPosition={staticWidgetRoom.styles}
                                            data={staticWidgetRoom.data}
                                            nesteds={staticWidgetRoom.nesteds}
                                            goToLanding={goToLanding}
                                            goToRoomShop={goToRoomShop}
                                            goToMessages={goToMessages}
                                            goToCuiner={goToCuiner}
                                        />
                                    ) : null}
                                </div>
                            )}
                            {!isPortal && pairingSuccess && pairingMessageEnabled ? (
                                <div className="w-full absolute left-0 p-4 bg-neutral-contrast-90 text-neutral rounded mb-1 shadow z-10 rounded-t-none">
                                    <div className="w-full flex items-center">
                                        <div className="w-11/12 text-center">
                                            <span className="font-bold">{t("connected to room tv")}</span>
                                        </div>
                                        <div
                                            className="w-1/12 text-center cursor-pointer"
                                            id="close-pairing-message"
                                            onClick={() => {
                                                dispatch(
                                                    updateRoom({
                                                        projectRef: project.ref,
                                                        number: room.number,
                                                        data: { showPairingMessage: false },
                                                    })
                                                );
                                            }}
                                        >
                                            <i className="icon icon-close"></i>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="flex w-full" key="landing-main-contrainer" id="landing-main-contrainer">
                                <div
                                    className={`landing-container`}
                                    style={{
                                        ...(baseStyles || {}),
                                        maxWidth: "100vw",
                                        height: maxY + 100 + "px",
                                        minHeight: "100vh",
                                    }}
                                    id="landing-container"
                                >
                                    {portalStep === "redirect" ? (
                                        <>
                                            Redirecting...
                                            <Loading />
                                        </>
                                    ) : portalFullMode && widgets ? (
                                        widgets
                                            .filter((w) => w && w.type === "wifilogin")
                                            .map((widget, index) => (
                                                <UseWidgetContainer
                                                    id={index + "-" + widget.type}
                                                    key={"portal-" + index}
                                                    type={"portal"}
                                                    currentPosition={widget.styles}
                                                    data={widget.data}
                                                    nesteds={widget.nesteds}
                                                    goToLanding={goToLanding}
                                                    goToRoomShop={goToRoomShop}
                                                    goToMessages={goToMessages}
                                                    goToCuiner={goToCuiner}
                                                />
                                            ))
                                    ) : widgets ? (
                                        widgets.map((widget, index) => {
                                            return (
                                                <div key={index} style={widget.styles}>
                                                    <UseWidgetContainer
                                                        id={index + "-" + widget.type}
                                                        key={index}
                                                        type={widget.type}
                                                        currentPosition={widget.styles}
                                                        data={widget.data}
                                                        nesteds={widget.nesteds}
                                                        goToLanding={goToLanding}
                                                        goToRoomShop={goToRoomShop}
                                                        goToMessages={goToMessages}
                                                        goToCuiner={goToCuiner}
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="w-full text-center my-5"
                            key="landing-main-contrainer"
                            id="landing-main-contrainer"
                        >
                            <span className="">{t("more information will be available soon")}</span>
                        </div>
                    )}
                </section>
                {showTabs ? <Tabs /> : null}
            </section>
        </>
    );
};

const ensureFonts = (theme, widgets) => {
    var fonts = [
        "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap",
    ];

    // Add theme fonts
    if (theme && theme.fonts) {
        if (theme.fonts.heading && theme.fonts.heading.font) {
            fonts.push(getFontURL(theme.fonts.heading.font));
        }
        if (theme.fonts.paragraph && theme.fonts.paragraph.font) {
            fonts.push(getFontURL(theme.fonts.paragraph.font));
        }
    }

    // Add widget fonts
    const getWidgetFonts = (widget) => {
        var fonts = [];
        if (widget && widget.nesteds && widget.nesteds.length > 0) {
            widget.nesteds.map((w) => {
                if (w) {
                    fonts = [...fonts, ...getWidgetFonts({ data: w })];
                }
                return w;
            });
        }
        if (widget && widget.data && widget.data.fonts && widget.data.fonts.length > 0) {
            widget.data.fonts.map((font) => {
                fonts.push(getFontURL(font));
                return font;
            });
        }
        return fonts;
    };
    if (widgets && widgets.length > 0) {
        widgets.map((widget) => {
            fonts = [...fonts, ...getWidgetFonts(widget)];
            return widget;
        });
    }

    return (
        <>
            {fonts.map((fontURL, index) => (
                <link key={index} href={fontURL} type="text/css" rel="stylesheet" />
            ))}
        </>
    );
};

const arrangeGrid = ({ device, grid }) => {
    if (!device) device = {};
    if (!grid) grid = {};
    const rows = Number(grid.Rows) || 0;
    const cols = Number(grid.Cols) || 0;
    const ratio = Number(device.ratio) || 0;
    const width = Number(device.width) || 0;
    let data = {
        cols: {
            max: 0,
            size: 0,
        },
        rows: {
            max: 0,
            size: 0,
        },
    };
    if (cols > 0) data.cols.max = cols;
    if (rows > 0) data.rows.max = rows;
    if (width > 0) {
        data.cols.size = data.cols.max > 0 ? (width / data.cols.max).toFixed(2) : 0;
        data.rows.size = data.rows.max > 0 && ratio > 0 ? (width / ratio / data.rows.max).toFixed(2) : 0;
    }
    return data;
};

export default Landing;
