import React, { useRef } from "react";
import { getPixelPosition } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLandingSession from "hooks/useLandingSession";

const GridPin = ({
    cellWidth,
    id,
    x,
    y,
    cellHeight,
    zoomScale,
    imageSrc,
    icon,
    zIndex = null,
    activePoint,
    setActivePoint,
}) => {
    const { left, top } = getPixelPosition(x, y, cellWidth, cellHeight);

    const { landing } = useLandingSession();

    const designColors = landing?.theme?.colors || {};
    const editorColors = landing?.mapsStyles?.style?.pin?.status || {};

    const getColor = (pathColor, fallback) => pathColor || fallback;

    const activeBackground = designColors.activeBackground || "white";
    const activeForeground = designColors.activeForeground || "black";
    const defaultBackground = designColors.defaultBackground || "white";
    const defaultForeground = designColors.defaultForeground || "black";

    const editorActive = editorColors?.active?.colors || {};
    const editorDefault = editorColors?.default?.colors || {};

    const editorActiveBackground = getColor(editorActive.bgColor, activeBackground);
    const editorActiveForeground = getColor(editorActive.fgColor, activeForeground);
    const editorDefaultBackground = getColor(editorDefault.bgColor, defaultBackground);
    const editorDefaultForeground = getColor(editorDefault.fgColor, defaultForeground);

    const generateId = (prefix) => `${prefix}-${Math.random().toString(36).substr(2, 9)}`;

    const clipIdRef = useRef(generateId("clip-shape"));
    const shadowIdRef = useRef(generateId("shadow-id"));

    const scaleConst = 2;
    const isAnActivePoint = activePoint === id;

    return (
        <div
            id={`pin-${id}`}
            onClick={() => {
                setActivePoint(id);
            }}
            style={{
                position: "absolute",
                left,
                top,
                width: cellWidth * 2,
                height: cellHeight * 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: zIndex,
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    transform: `scale(${scaleConst / zoomScale})`,
                }}
            >
                <svg
                    width="30"
                    height="35"
                    viewBox="0 0 30 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full h-full relative overflow-visible"
                >
                    <defs>
                        <clipPath id={clipIdRef.current}>
                            <path d="M29.5 14.433C29.5 16.2941 28.6731 18.4835 27.3554 20.7588C26.0443 23.0229 24.2794 25.3177 22.4755 27.3761C20.6729 29.4331 18.8412 31.2427 17.4047 32.5357C16.686 33.1827 16.0709 33.6961 15.6114 34.0449C15.3806 34.2201 15.1966 34.348 15.0627 34.4297C15.0392 34.444 15.0183 34.4562 15 34.4666C14.9817 34.4562 14.9608 34.444 14.9373 34.4297C14.8034 34.348 14.6194 34.2201 14.3886 34.0449C13.9291 33.6961 13.314 33.1827 12.5953 32.5357C11.1588 31.2427 9.32708 29.4331 7.52447 27.3761C5.72058 25.3177 3.95574 23.0229 2.6446 20.7588C1.32691 18.4835 0.5 16.2941 0.5 14.433C0.5 6.756 6.97354 0.5 15 0.5C23.0265 0.5 29.5 6.756 29.5 14.433Z" />
                        </clipPath>
                        <filter id={shadowIdRef.current} x="0%" y="-20%" width="140%" height="140%">
                            <feDropShadow dx="2" dy="2" stdDeviation="3" floodColor="#000" floodOpacity="0.3" />
                        </filter>
                    </defs>
                    <g filter={`url(#${shadowIdRef.current})`}>
                        <rect
                            x="0"
                            y="0"
                            width="30"
                            height="35"
                            fill={isAnActivePoint ? editorActiveBackground : editorDefaultBackground}
                            style={{ transition: "fill 1s ease" }}
                            clipPath={`url(#${clipIdRef.current})`}
                        />
                        {imageSrc && (
                            <image
                                href={imageSrc}
                                x="0"
                                y="0"
                                width="30"
                                height="35"
                                clipPath={`url(#${clipIdRef.current})`}
                                preserveAspectRatio="xMidYMid slice"
                                transform="translate(2.25,2.625) scale(0.85)"
                            />
                        )}

                        {!imageSrc && icon && (
                            <>
                                <foreignObject
                                    x="0"
                                    y="0"
                                    width="30"
                                    height="35"
                                    clipPath={`url(#${clipIdRef.current})`}
                                >
                                    <div
                                        className="w-full h-full flex items-center justify-center"
                                        xmlns="http://www.w3.org/1999/xhtml"
                                    >
                                        <FontAwesomeIcon
                                            icon={[icon.lib, icon.icon]}
                                            style={{
                                                width: "55%",
                                                height: "55%",
                                                marginTop: "-15%",
                                                marginLeft: "0%",
                                                color: isAnActivePoint
                                                    ? editorActiveForeground
                                                    : editorDefaultForeground,
                                                transition: "color 1s ease",
                                            }}
                                        />
                                    </div>
                                </foreignObject>
                            </>
                        )}
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default GridPin;
