import { useMemo, useState, useEffect } from "react";
import { arrangeGridPointOfInterest } from "./utils";

export function useGridCalculations({
    categories = [],
    activeCategory,
    dimensions,
    totalColumns = 50,
    totalRows = 29,
}) {
    const cellWidth = dimensions.width / totalColumns;
    const cellHeight = dimensions.height / totalRows;

    const allPoints = useMemo(() => {
        return categories.reduce((acc, category) => {
            const points = category?.mapCategoryInterestPoints.map((point, index) => ({
                category: category.id,
                // coord: point.coord,
                x: point.x,
                y: point.y,
                coord: [point.x, point.y],
                icon: point.icon,
                srcImage: point.srcImage,
                id: `${category.name}-${index}`,
                zIndex: point.zIndex,
            }));
            return acc.concat(points);
        }, []);
    }, [categories]);

    const filteredPoints = useMemo(() => {
        if (activeCategory === null || activeCategory === "all") {
            return allPoints;
        } else {
            return allPoints.filter((p) => p.category === activeCategory);
        }
    }, [allPoints, activeCategory]);

    const clusters = useMemo(() => {
        if (dimensions.width <= 0 || filteredPoints.length === 0) return [];
        const clusterThreshold = cellWidth * 1;
        const visited = new Array(filteredPoints.length).fill(false);
        const clusters = [];
        const dfs = (i, cluster) => {
            visited[i] = true;
            cluster.push(i);
            for (let j = 0; j < filteredPoints.length; j++) {
                if (!visited[j]) {
                    const centerI = [filteredPoints[i].x * cellWidth, filteredPoints[i].y * cellHeight];
                    const centerJ = [filteredPoints[j].x * cellWidth, filteredPoints[j].y * cellHeight];
                    const dx = centerI[0] - centerJ[0];
                    const dy = centerI[1] - centerJ[1];
                    const dist = Math.sqrt(dx * dx + dy * dy);
                    if (dist < clusterThreshold) {
                        dfs(j, cluster);
                    }
                }
            }
        };

        for (let i = 0; i < filteredPoints.length; i++) {
            if (!visited[i]) {
                const cluster = [];
                dfs(i, cluster);
                clusters.push(cluster);
            }
        }
        return clusters;
    }, [dimensions.width, filteredPoints, cellWidth, cellHeight]);

    const largestCluster = useMemo(() => {
        let lc = null;
        clusters.forEach((cluster) => {
            if (cluster.length > 1) {
                if (!lc || cluster.length > lc.length) {
                    lc = cluster;
                }
            }
        });
        return lc || filteredPoints.map((_, idx) => idx);
    }, [clusters, filteredPoints]);

    const largestClusterBox = useMemo(() => {
        if (activeCategory === null || activeCategory === "all" || largestCluster.length <= 1) {
            return {
                left: 0,
                top: 0,
                width: dimensions.width,
                height: dimensions.height,
            };
        } else {
            const lefts = largestCluster.map((i) => (filteredPoints[i].coord[0] - 1) * cellWidth);
            const tops = largestCluster.map((i) => (filteredPoints[i].coord[1] - 1) * cellHeight);
            const rights = largestCluster.map((i) => (filteredPoints[i].coord[0] - 1) * cellWidth + cellWidth * 2);
            const bottoms = largestCluster.map((i) => (filteredPoints[i].coord[1] - 1) * cellHeight + cellHeight * 2);

            let left = Math.min(...lefts) + cellWidth;
            let top = Math.min(...tops) + cellHeight;
            let width = Math.max(...rights) - Math.min(...lefts);
            let height = Math.max(...bottoms) - Math.min(...tops);

            const minWidth = dimensions.width * 0.75;
            if (width < minWidth) {
                const centerX = left + width / 2;
                width = minWidth;
                left = centerX - width / 2;
            }

            const centerX = left + width / 2;
            width = width * 1.05;
            left = centerX - width / 2;

            return { left, top, width, height };
        }
    }, [activeCategory, largestCluster, filteredPoints, cellWidth, cellHeight, dimensions]);

    const centerColumn = Math.floor(totalColumns / 2);
    const centerAreaBox = useMemo(
        () => ({
            left: (centerColumn - 6) * cellWidth,
            top: 0,
            width: cellWidth * 12,
            height: Math.ceil(dimensions.height),
        }),
        [centerColumn, cellWidth, dimensions.height]
    );

    return {
        cellWidth,
        cellHeight,
        filteredPoints,
        clusters,
        largestCluster,
        largestClusterBox,
        centerAreaBox,
    };
}

export function useMapDataGrid(interestPoints, categories) {
    const [gridPoints, setGridPoints] = useState([]);
    const [gridCategories, setGridCategories] = useState([]);
    const [activePoint, setActivePoint] = useState(null);

    useEffect(() => {
        if (interestPoints.length > 0) {
            const arrangedPoints = arrangeGridPointOfInterest(interestPoints);
            setGridPoints(arrangedPoints);
            setActivePoint(arrangedPoints[0]?.id || null);
        }
    }, [interestPoints]);

    useEffect(() => {
        if (categories.length > 0 && gridPoints.length > 0) {
            const interestPointMap = {};
            gridPoints.forEach((point) => {
                if (!interestPointMap[point.id]) {
                    interestPointMap[point.id] = [];
                }
                interestPointMap[point.id].push(point);
            });

            const enrichedCategories = categories.map((category) => {
                const expandedPoints = [];

                category.mapCategoryInterestPoints.forEach((interestMapping) => {
                    const matches = interestPointMap[interestMapping.mapInterestPointID];

                    if (matches && matches.length > 0) {
                        matches.forEach((match) => {
                            expandedPoints.push({
                                ...interestMapping,
                                ...match,
                            });
                        });
                    } else {
                        expandedPoints.push(interestMapping);
                    }
                });

                return {
                    ...category,
                    mapCategoryInterestPoints: expandedPoints,
                };
            });

            setGridCategories(enrichedCategories);
        }
    }, [categories, gridPoints]);

    return { gridPoints, gridCategories, activePoint, setActivePoint };
}

export function useContainerDimensions(ref, multiplier = 1.01) {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        function updateDimensions() {
            if (ref.current && ref.current.parentElement) {
                const container = ref.current.parentElement;
                const containerWidth = container.clientWidth;
                const containerHeight = container.clientHeight;
                let baseHeight = (containerWidth * 9) / 16;
                let rectHeight = baseHeight * multiplier;
                let rectWidth = containerWidth;
                if (rectHeight > containerHeight) {
                    rectHeight = containerHeight;
                    baseHeight = containerHeight / multiplier;
                    rectWidth = (baseHeight * 16) / 9;
                }
                setDimensions({ width: rectWidth, height: rectHeight });
            }
        }

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [ref, multiplier]);

    return dimensions;
}
