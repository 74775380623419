import gql from "graphql-tag";

export const HELLO = {
    query: gql`
        mutation hello($push: String) {
            hello(pushToken: $push)
        }
    `,
};

export const SET_TOKEN_LANGUAGE = {
    query: gql`
        mutation setTokenLanguage($language: LanguageCode!) {
            setTokenLanguage(language: $language) {
                token
            }
        }
    `,
};

export const ACCEPT_TERMS = {
    query: gql`
        mutation {
            updateUserProfile(termsAccepted: true) {
                user {
                    termsAccepted
                }
            }
        }
    `,
};

export const UPDATE_PROFILE = {
    query: gql`
        mutation updateUserProfile(
            $gender: NullableGender
            $name: NullableString!
            $surname: NullableString!
            $birthDate: NullableString
            $country: NullableCountryCode
            $phone: NullableString
            $language: NullableLanguageCode
        ) {
            updateUserProfile(
                gender: $gender
                name: $name
                surname: $surname
                birthDate: $birthDate
                country: $country
                phone: $phone
                language: $language
            ) {
                auth {
                    token
                }
                user {
                    ref
                    email
                    name
                    surname
                    auth {
                        google
                        apple
                        password
                    }
                    birthDate
                    country
                    language
                    gender
                    phone
                    emailVerified
                    termsAccepted
                    marketingAccepted
                }
            }
        }
    `,
};

export const UPDATE_PASSWORD = {
    query: gql`
        mutation updatePassword($password: Password!) {
            updatePassword(password: $password) {
                ref
                email
                name
                surname
                auth {
                    google
                    apple
                    password
                }
                birthDate
                country
                language
                gender
                phone
                emailVerified
                termsAccepted
                marketingAccepted
            }
        }
    `,
};

export const SIGN_IN = {
    query: gql`
        mutation signIn($email: Email!, $password: String, $code: String, $push: String) {
            signIn(email: $email, password: $password, code: $code, pushToken: $push) {
                auth {
                    token
                }
                user {
                    ref
                    email
                    name
                    surname
                    auth {
                        google
                        apple
                        password
                    }
                    birthDate
                    country
                    language
                    gender
                    phone
                    emailVerified
                    termsAccepted
                    marketingAccepted
                }
            }
        }
    `,
};

export const SIGN_IN_GOOGLE = {
    query: gql`
        mutation signInGoogle($token: String!, $push: String) {
            signInGoogle(platform: web, token: $token, pushToken: $push) {
                auth {
                    token
                }
                user {
                    ref
                    email
                    name
                    surname
                    auth {
                        google
                        apple
                        password
                    }
                    birthDate
                    country
                    language
                    gender
                    phone
                    emailVerified
                    termsAccepted
                    marketingAccepted
                }
            }
        }
    `,
};

export const SEND_RESET_CODE = {
    query: gql`
        mutation sendResetCode($email: Email!) {
            sendResetCode(email: $email) {
                email
                hash
            }
        }
    `,
};

export const VERIFY_RESET_CODE = {
    query: gql`
        mutation verifyResetCode($email: Email!, $code: String!, $hash: String!) {
            verifyResetCode(email: $email, code: $code, hash: $hash)
        }
    `,
};

export const RESET_PASSWORD = {
    query: gql`
        mutation resetPassword($email: Email!, $code: String!, $hash: String!, $password: Password!) {
            resetPassword(email: $email, code: $code, hash: $hash, password: $password) {
                auth {
                    token
                }
                user {
                    ref
                    email
                    name
                    surname
                    auth {
                        google
                        apple
                        password
                    }
                    birthDate
                    country
                    language
                    gender
                    phone
                    emailVerified
                    termsAccepted
                    marketingAccepted
                }
            }
        }
    `,
};

export const SIGN_UP = {
    query: gql`
        mutation signUp(
            $language: LanguageCode
            $country: CountryCode
            $birthDate: Date
            $email: Email!
            $name: String!
            $surname: String!
            $password: Password!
            $phone: String
            $push: String
            $marketingAccepted: Boolean
        ) {
            signUp(
                language: $language
                country: $country
                birthDate: $birthDate
                email: $email
                name: $name
                surname: $surname
                password: $password
                phone: $phone
                pushToken: $push
                marketingAccepted: $marketingAccepted
            ) {
                auth {
                    token
                }
                user {
                    ref
                    email
                    name
                    surname
                    auth {
                        google
                        apple
                        password
                    }
                    birthDate
                    country
                    language
                    gender
                    phone
                    emailVerified
                    termsAccepted
                    marketingAccepted
                }
            }
        }
    `,
};

export const SEND_EMAIL_VERIFICATION_CODE = {
    query: gql`
        mutation sendEmailVerificationCode {
            sendEmailVerificationCode {
                hash
            }
        }
    `,
};

export const VERIFY_EMAIL_VERIFICATION_CODE = {
    query: gql`
        mutation verifyEmailVerificationCode($code: String!, $hash: String!) {
            verifyEmailVerificationCode(code: $code, hash: $hash) {
                ref
                email
                name
                surname
                auth {
                    google
                    apple
                    password
                }
                birthDate
                country
                language
                gender
                phone
                emailVerified
                termsAccepted
                marketingAccepted
            }
        }
    `,
};

export const SEND_CUINER_ORDER_MAIL = {
    query: gql`
        mutation sendCuinerOrderMail(
            $summary: [KeyValue]!
            $roomNumber: Int!
            $comments: String
            $restaurant: String!
            $items: [CuinerProduct]!
        ) {
            sendCuinerOrderMail(
                summary: $summary
                roomNumber: $roomNumber
                comments: $comments
                restaurant: $restaurant
                items: $items
            )
        }
    `,
};

export const AUTHORIZE_PAYMENT_INVOICE = {
    query: gql`
        mutation invoiceAuthorizePayment($hotelRef: ID!, $roomNumber: Int, $password: String) {
            invoiceAuthorizePayment(hotelRef: $hotelRef, roomNumber: $roomNumber, password: $password)
        }
    `,
};

export const EXPRESS_CHECKOUT = {
    query: gql`
        mutation expressCheckout($hotelRef: ID!, $roomNumber: Int, $password: String, $totalAmount: Float!) {
            expressCheckout(
                hotelRef: $hotelRef
                roomNumber: $roomNumber
                password: $password
                totalAmount: $totalAmount
            )
        }
    `,
};

export const SEND_TRACK_SCREEN = {
    query: gql`
        mutation SendTrackScreen($path: String!, $projectRef: String!) {
            sendTrackScreen(path: $path, projectRef: $projectRef)
        }
    `,
};

export const SEND_TRACK_EVENT = {
    query: gql`
        mutation SendTrackEvent(
            $name: String!
            $sectionName: String
            $hotelName: String
            $room: String
            $projectRef: String!
        ) {
            sendTrackEvent(
                name: $name
                sectionName: $sectionName
                hotelName: $hotelName
                room: $room
                projectRef: $projectRef
            )
        }
    `,
};

export const SEND_TRACK_EVENT_GENERAL_MOBILE = {
    query: gql`
        mutation sendTrackEventGeneralMobile(
            $event: TrackEventEvent!
            $hotelRef: ID!
            $date: DateTime!
            $params: TrackEventParams
            $source: TrackSource!
        ) {
            sendTrackEventGeneralMobile(
                source: $source
                event: $event
                hotelRef: $hotelRef
                dateTime: $date
                params: $params
            ) {
                error
                ok
            }
        }
    `,
};

export const SEND_TRACK_SCREEN_GENERAL_MOBILE = {
    query: gql`
        mutation sendTrackScreenGeneralMobile(
            $id: String!
            $date: DateTime!
            $hotelRef: ID!
            $name: String
            $type: TrackScreenType!
            $source: TrackSource!
        ) {
            sendTrackScreenGeneralMobile(
                source: $source
                hotelRef: $hotelRef
                dateTime: $date
                id: $id
                type: $type
                name: $name
            ) {
                error
                ok
            }
        }
    `,
};

export const SEND_SATISFACTION_LEVEL = {
    query: gql`
        mutation sendSatisfactionLevel($roomNumber: Int!, $hotelRef: ID!, $guestID: Int64!, $satisfactionLevel: Int!) {
            sendFeedback(
                roomNumber: $roomNumber
                hotelRef: $hotelRef
                guestID: $guestID
                satisfactionLevel: $satisfactionLevel
            ) {
                error
                ok
            }
        }
    `,
};
