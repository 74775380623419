import React from "react";

const TabSlider = ({
    className,
    options = [],
    selected,
    selectedClassname,
    notSelectedClassname,
    selectedStyle,
    notSelectedStyle,
}) => {
    const borderBottom = selectedStyle?.color;
    const borderBottomNotSelected = borderBottom?.includes("rgb")
        ? borderBottom.replace(/[^,]*?(?=\))/, "0.2")
        : `${borderBottom}22`;

    return (
        <div className={`${className} flex overflow-y-scroll`} style={{ scrollbarWidth: "none" }}>
            {options.map((option, index) => (
                <button
                    key={`tab-option-${index}`}
                    className={`flex-1 relative px-4 py-2 border-b-2 ${
                        selected == option.value ? selectedClassname : notSelectedClassname
                    }`}
                    style={{
                        minWidth: "fit-content",
                        ...(selected == option.value
                            ? { ...selectedStyle, borderColor: borderBottom }
                            : { ...notSelectedStyle, borderColor: borderBottomNotSelected }),
                    }}
                    onClick={() => {
                        option.onClick(option.value);
                    }}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default TabSlider;
